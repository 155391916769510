export const PORTFOLIOS = [
  'awumba',
  'wimiko',
  'wojs',
  'delpapa',
  'sugar',
  'kindergarten',
  'hiking',
  'seremak',
];
