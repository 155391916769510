import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { PortfolioItem } from '../components';
import CenteredContainer from '../components/centered-container/centered-container';
import HomepageReason from '../components/homepage-reason/homepage-reason';
import { PORTFOLIOS } from '../config/portfolio';
import { PORTFOLIO_DEFINITIONS } from '../config/portfolio-definitions';
import { iconQuoteImg } from '../images';
import gradientTop from '../images/gradient_top.jpg';
import homepageReason1Img from '../images/home-page-reasons/icon_1_look.png';
import homepageReason2Img from '../images/home-page-reasons/icon_2_contact.png';
import homepageReason3Img from '../images/home-page-reasons/icon_3_cost.png';
import { NavbarContext } from '../layouts/hooks';

const portfolioClassNameMapper = (portfolio: string) => {
  switch (portfolio) {
    case 'awumba':
    case 'seremak':
    case 'hiking':
    case 'wimiko':
      return 'w-full md:w-[calc(50%-15px)] aspect-[1.4] md:aspect-[1.49] border';
    case 'wojs':
    case 'kindergarten':
      return 'w-full md:w-[calc(66.6666%-15px)] aspect-[1.4] md:aspect-[2] border';
    case 'sugar':
    case 'delpapa':
      return 'w-full md:w-[calc(33.3333%-15px)] aspect-[1.4] md:aspect-square border';
  }
};

const IndexPage = ({ data }: any) => {
  const { t } = useTranslation();
  const navbarContext = useContext(NavbarContext);
  useEffect(() => {
    const oldMode = navbarContext.transparent;
    navbarContext.setTransparent(true);
    return () => {
      navbarContext.setTransparent(oldMode);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>{t('index.page-title')}</title>
      </Helmet>
      <section className="relative">
        <div
          className="absolute bottom-[55%] -z-50 h-[calc(100%+var(--desktop-menu-height))] w-full bg-cover bg-right"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 100%), url("${gradientTop}")`,
          }}
          aria-hidden="true"
        ></div>
        <CenteredContainer className="relative flex flex-col items-center">
          <GatsbyImage
            className="!absolute -left-4 -right-1 -z-50 h-[66%] w-[100vw] opacity-50 md:left-1/2 md:bottom-1/2 md:h-[calc(76%+var(--desktop-menu-height))]"
            aria-hidden="true"
            image={
              data.images.edges.find(
                (e: any) => e.node.fixed.originalName === 'bg_top_section.png'
              )?.node.gatsbyImageData
            }
            alt=""
            objectPosition="left"
            imgClassName="!object-cover md:!object-contain"
          />
          <h1
            className="mt-20 mb-4 max-w-6xl text-center font-secondary text-3xl font-semibold text-header md:mt-44 md:mb-9 md:text-7xl md:!leading-[78px]"
            dangerouslySetInnerHTML={{
              __html: t('index.section-1.text-1'),
            }}
          />
          <p
            className="max-w-xs text-center text-sm font-semibold md:max-w-3xl md:text-2xl"
            dangerouslySetInnerHTML={{
              __html: t('index.section-1.text-2'),
            }}
          />
          <div className="mt-14 mb-9 md:mb-9">
            <a className="button-primary mr-2 md:mr-4" href="#contact-form">
              {t('shared.send-message')}
            </a>
            <a className="button-secondary" href="/#portfolio">
              {t('shared.see-portfolio')}
            </a>
          </div>
          <StaticImage
            className="mt-8 max-w-full md:mt-24 md:max-w-3xl"
            src="../images/design_things.png"
            alt="Design art"
          />
        </CenteredContainer>
      </section>
      <section className="py-9 md:pt-36 md:pb-20">
        <CenteredContainer>
          <header className="text-center font-secondary text-2xl font-bold text-header md:text-4xl">
            <h2>{t('index.section-2.text-1')}</h2>
          </header>
          <ul
            className="mt-6 list-none md:mt-[4.5rem] md:grid md:grid-cols-3 md:gap-8"
            role="list"
          >
            <li>
              <HomepageReason
                title={t('index.section-2.reasons-list.reason-1-title')}
                text={t('index.section-2.reasons-list.reason-1-text')}
                imgSrc={homepageReason1Img}
              ></HomepageReason>
            </li>
            <li className="mt-10 md:mt-0">
              <HomepageReason
                title={t('index.section-2.reasons-list.reason-2-title')}
                text={t('index.section-2.reasons-list.reason-2-text')}
                imgSrc={homepageReason2Img}
              ></HomepageReason>
            </li>
            <li className="mt-4 md:mt-0">
              <HomepageReason
                title={t('index.section-2.reasons-list.reason-3-title')}
                text={t('index.section-2.reasons-list.reason-3-text')}
                imgSrc={homepageReason3Img}
              ></HomepageReason>
            </li>
          </ul>
        </CenteredContainer>
      </section>
      <section className="relative pt-8 pb-6 md:pt-0">
        <CenteredContainer>
          <header className="mb-9 text-center font-secondary text-2xl font-bold text-header md:mb-14 md:text-4xl">
            <h2>{t('index.section-3.offer')}</h2>
          </header>
          <article className="flex grid-cols-2 flex-col-reverse md:grid md:gap-8">
            <div className="relative pt-6 pb-0 md:pt-16 md:pb-16">
              <div className="absolute top-0 bottom-0 right-[-140px] left-[-99999px] -z-10 hidden bg-[color:rgb(var(--gray-light-1))] md:block" />
              <h6 className="text-semibold text-xs text-[color:rgb(var(--color-blue))] md:text-lg">
                {t('index.section-3.branding-headline')}
              </h6>
              <h4 className="mt-2 font-secondary text-2xl font-semibold text-header md:mt-6 md:text-4xl">
                {t('index.section-3.branding')}
              </h4>
              <div className="mt-4 text-sm prose-p:mt-2 md:mt-6 md:text-base">
                <Trans t={t} i18nKey="index.section-3.branding-description" />
              </div>
              <div className="mt-6 mb-9 md:mt-11 md:mb-9">
                <Link
                  className="button-primary mr-2 after:ml-1 after:content-['>'] md:mr-4"
                  to="/branding"
                >
                  {t('shared.know-more')}
                </Link>
                <a className="button-secondary" href="#portfolio">
                  {t('shared.see-portfolio')}
                </a>
              </div>
            </div>
            <div className="flex items-center">
              <StaticImage
                className="mt-6"
                src="../images/home-page-features/feature_branding.jpg"
                alt="Branding - picture"
              />
            </div>
          </article>
          <article className="flex grid-cols-2 flex-col md:mt-20 md:grid md:gap-8">
            <div className="flex items-center">
              <StaticImage
                className="mt-6"
                src="../images/home-page-features/feature_webdesign.jpg"
                alt="WWW Design - picture"
              />
            </div>
            <div className="relative pt-6 pb-0 md:pt-16 md:pb-16">
              <div className="absolute top-0 bottom-0 right-[-99999px] left-[-140px] -z-10 hidden bg-[color:rgb(var(--gray-light-1))] md:block" />
              <h6 className="text-semibold text-xs text-[color:rgb(var(--color-blue))] md:text-lg">
                {t('index.section-3.web-pages-headline')}
              </h6>
              <h4 className="mt-2 font-secondary text-2xl font-semibold text-header md:mt-6 md:text-4xl">
                {t('index.section-3.web-pages')}
              </h4>
              <div className="mt-4 max-w-md text-sm prose-p:mt-2 md:mt-6 md:text-base">
                <Trans t={t} i18nKey="index.section-3.web-pages-description" />
              </div>
              <ul
                className="mt-6 flex justify-between md:justify-start"
                role="list"
              >
                <li className="flex items-center font-semibold">
                  <i className="mr-4 inline-flex h-8 w-8 items-center rounded-full bg-[color:rgb(var(--color-blue))] pl-1 font-icons text-lg text-white after:content-['\e876']" />
                  {t('index.section-3.web-pages-attr-1')}
                </li>
                <li className="flex items-center font-semibold md:ml-32">
                  <i className="mr-4 inline-flex h-8 w-8 items-center rounded-full bg-[color:rgb(var(--color-blue))] pl-1 font-icons text-lg text-white after:content-['\e876']" />
                  {t('index.section-3.web-pages-attr-2')}
                </li>
              </ul>
              <div className="mt-6 mb-9 md:mt-11 md:mb-9">
                <Link
                  className="button-primary mr-2 after:ml-1 after:content-['>'] md:mr-4"
                  to="/web-design"
                >
                  {t('shared.know-more')}
                </Link>
                <a className="button-secondary" href="#portfolio">
                  {t('shared.see-portfolio')}
                </a>
              </div>
            </div>
          </article>
        </CenteredContainer>
      </section>
      <section
        className={`md:min-h-auto relative mt-[148px] flex min-h-[307px] items-end justify-center 
      bg-navy-dark pt-32 pb-11 text-white md:mt-0 md:block md:py-14`}
      >
        <CenteredContainer className="flex gap-16">
          <StaticImage
            className="!absolute top-[-108px] right-0 left-0 mx-auto w-[228px] md:!relative md:top-0 md:mx-0 md:w-[343px]"
            src="../images/balls.png"
            alt="Colorfull balls"
            aria-hidden="true"
          />
          <div className="flex flex-col items-start justify-center text-center md:text-left">
            <h3
              className="mb-6 text-lg font-bold md:mb-9 md:text-4xl"
              dangerouslySetInnerHTML={{ __html: t('index.section-4.text') }}
            />
            <div className="flex w-full justify-center md:block md:w-auto">
              <a className="button-primary mr-2 md:mr-4" href="#contact-form">
                {t('shared.send-message')}
              </a>
              <a className="button-secondary" href="tel:509568702">
                {t('shared.call-me')}
              </a>
            </div>
          </div>
        </CenteredContainer>
      </section>
      <section className="pt-16 pb-14 md:pb-28" id="portfolio">
        <CenteredContainer>
          <header className="mb-16 text-center font-secondary text-2xl font-semibold text-header md:text-4xl">
            <h2>{t('index.section-5.title')}</h2>
          </header>
          <div className="flex flex-wrap gap-[30px]">
            {PORTFOLIOS.map((p) => {
              const portfolioDef = PORTFOLIO_DEFINITIONS.find(
                (def) => def.key === p
              );
              if (!portfolioDef) {
                return null;
              }
              return (
                <PortfolioItem
                  className={portfolioClassNameMapper(p)}
                  key={p}
                  portfolio={p}
                  title={t(portfolioDef?.titleKey)}
                  description={t(portfolioDef?.descriptionKey)}
                  gatsbyImage={
                    data.images.edges.find(
                      (e: any) => e.node.fixed.originalName === portfolioDef.img
                    )?.node.gatsbyImageData
                  }
                />
              );
            })}
          </div>
        </CenteredContainer>
      </section>
      <section className={`relative py-[48px] text-white md:py-[275px]`}>
        <StaticImage
          className="!absolute top-0 bottom-0 left-0 right-0 -z-10 h-full object-cover"
          src="../images/clients_opinion.jpg"
          aria-hidden="true"
          alt=""
        />
        <CenteredContainer className="grid-cols-6 gap-[30px] md:grid">
          <img
            className="h-[36px] md:h-[94px]"
            src={iconQuoteImg}
            alt="Quote symbol"
            aria-hidden="true"
          />
          <div className="col-span-4 mt-6 md:mt-0">
            <p className="font-secondary italic md:text-2xl md:font-semibold">
              {t('index.client-opinions.quote')}
            </p>
            <p className="mt-10">
              <span className="text-sm md:text-lg">Wojciech Stegienko</span>
              <br />
              <span className="text-xs md:text-base">
                {t('index.client-opinions.author-description')}
              </span>
            </p>
          </div>
        </CenteredContainer>
      </section>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    images: allImageSharp {
      edges {
        node {
          id
          gatsbyImageData(placeholder: NONE)
          fixed {
            originalName
          }
        }
      }
    }
  }
`;
