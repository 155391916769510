export interface Portfolio {
  key: string;
  titleKey: string;
  descriptionKey: string;
  img: string;
  fullImg: string;
}

export const PORTFOLIO_DEFINITIONS: Portfolio[] = [
  {
    key: 'awumba',
    titleKey: 'index.portfolio.awumba-title',
    descriptionKey: 'index.portfolio.awumba-description',
    img: 'img_awumba.jpg',
    fullImg: 'portfolio_awumba.jpg',
  },
  {
    key: 'wimiko',
    titleKey: 'index.portfolio.wimiko-title',
    descriptionKey: 'index.portfolio.wimiko-description',
    img: 'img_wimiko.jpg',
    fullImg: 'portfolio_wimiko.jpg',
  },
  {
    key: 'wojs',
    titleKey: 'index.portfolio.wojs-title',
    descriptionKey: 'index.portfolio.wojs-description',
    img: 'img_wojs.jpg',
    fullImg: 'portfolio_wojs.jpg',
  },
  {
    key: 'delpapa',
    titleKey: 'index.portfolio.delpapa-title',
    descriptionKey: 'index.portfolio.delpapa-description',
    img: 'img_del_papa.jpg',
    fullImg: 'portfolio_delpapa.jpg',
  },
  {
    key: 'sugar',
    titleKey: 'index.portfolio.sugar-title',
    descriptionKey: 'index.portfolio.sugar-description',
    img: 'img_sugar.jpg',
    fullImg: 'portfolio_sugar.jpg',
  },
  {
    key: 'kindergarten',
    titleKey: 'index.portfolio.kindergarten-title',
    descriptionKey: 'index.portfolio.kindergarten-description',
    img: 'img_kindergarten.jpg',
    fullImg: 'portfolio_kindergarten.jpg',
  },
  {
    key: 'hiking',
    titleKey: 'index.portfolio.hiking-title',
    descriptionKey: 'index.portfolio.hiking-description',
    img: 'img_hiking.jpg',
    fullImg: 'portfolio_hiking.jpg',
  },
  {
    key: 'seremak',
    titleKey: 'index.portfolio.seremak-title',
    descriptionKey: 'index.portfolio.seremak-description',
    img: 'img_seremak.jpg',
    fullImg: 'portfolio_seremak.jpg',
  },
];
